import { createRouter, createWebHistory } from 'vue-router';

import store from '@/store';

const routes = [
  {
    path: '/share/:pathMatch(.*)*',
    component: () => import('../views/Main/BaseTemplate.vue'),
    beforeEnter() {
      const sharedPath = window.location.href.split('/share/')[1];
      console.log(sharedPath);
      window.location.href = process.env.VUE_APP_SCHEME+sharedPath;
      setTimeout(() => {
        window.location.href = "/descargar";
      }, 3000);
    }
  },
  {
    path: '/',
    name: 'interfaces',
    component: () => import('../views/Main/BaseTemplate.vue'),
    children: [
      //Pagina de inicio
      {
        path: '',
        name: 'landing',
        meta: { title: 'Inicio ' },
        component: () => import('../components/landing/Landing.vue')
      },
      //Sección de sectores
      // {
      //   name: 'subsectorsList',
      //   path: 'subsector/:id',
      //   meta: { title: 'Subsectores ' },
      //   component: () => import('../components/sectors/SubsectorsList.vue'),
      // },
      {
        name: 'descarga',
        path: 'descargar',
        meta: { title: 'Descargar ' },
        // component: () => import('@/components/download/DownloadInterface.vue'),
        component: () => import('@/components/download/FormDownloadInterface.vue'),
      },
      //Sección de autenticación
      // {
      //   path: 'registro',
      //   name: 'userRegister',
      //   meta: { title: 'Registro ' },
      //   component: () => import('../components/register/UserRegister.vue')
      // },
      // {
      //   path: 'login',
      //   name: 'userLogin',
      //   meta: { title: 'login ' },
      //   component: () => import('../components/login/UserLogin.vue')
      // },
      //Sección de recuperación de contraseña
      // {
      //   path: 'recuperar-contrasena',
      //   name: 'recover',
      //   meta: { title: 'Recuperar contraseña ' },
      //   component: () => import('../components/user/UserRecoverPassword.vue')
      // },
      // {
      //   path: 'cambiar-contrasena',
      //   name: 'change',
      //   meta: { title: 'Cambiar contraseña ', requiresAuth: true },
      //   component: () => import('../components/user/UserChangePassword.vue')
      // },
      //Sección de listado de ubicaciones
      // {
      //   path: 'ubicaciones/:id',
      //   name: 'locations',
      //   meta: { title: 'Listas ' },
      //   component: () => import('../components/lists/LocationsList.vue')
      // },
      // {
      //   path: 'subsector/:subsector/ubicaciones/:id',
      //   name: 'subsectorLocations',
      //   meta: { title: 'Listas ' },
      //   component: () => import('../components/lists/LocationsList.vue')
      // },
      // {
      //   path: 'ubicaciones/filtros/:sector',
      //   name: 'filters',
      //   meta: { title: 'Filtros ' },
      //   component: () => import('../components/lists/ListFilters.vue')
      // },
      // {
      //   path: 'ubicacion/:id',
      //   name: 'details',
      //   meta: { title: 'Detalles ' },
      //   component: () => import('../components/Details/LocationDetails.vue')
      // },
      // {
      //   name: 'mapView',
      //   path: 'ubicaciones/:sector/mapa',
      //   component: () => import('../components/map/Map.vue'),
      //   meta: { title: 'Mapa ' },
      // },
      // {
      //   name: 'mapSubsectorView',
      //   path: '/subsector/:subsector/ubicaciones/:sector/mapa',
      //   component: () => import('../components/map/Map.vue'),
      //   meta: { title: 'Mapa ' },
      // },
      // {
      //   name: 'search',
      //   path: 'busqueda/:query',
      //   meta: { title: 'Búsqueda ' },
      //   component: () => import('../components/lists/SearchedLocationsList.vue'),
      // },
      //Sección de usuario
      // {
      //   path: 'configuracion',
      //   name: 'config',
      //   meta: { title: 'Configuración ', requiresAuth: true },
      //   component: () => import('../components/user/UserConfig.vue')
      // },
      // {
      //   path: 'favoritos',
      //   name: 'favorites',
      //   meta: { title: 'Favoritos ', requiresAuth: true },
      //   component: () => import('../components/user/UserFavorites.vue')
      // },
      //Sección estaticos
      // {
      //   name: 'staticDetails',
      //   path: 'estatico/:id',
      //   meta: { title: 'Estático detalles ' },
      //   component: () => import('../components/static/StaticDetails.vue'),

      // },
      // {
      //   name: 'staticList',
      //   path: 'estaticos/:id',
      //   meta: { title: 'Estáticos ' },
      //   component: () => import('../components/static/StaticList.vue'),
      // },
      //Sección participa
      // {
      //   path: 'participa',
      //   name: 'participate',
      //   meta: { title: '¡Participa! ' },
      //   component: () => import('../components/participate/Participate.vue')
      // },
      // {
      //   path: 'campana/:id',
      //   name: 'campaign',
      //   meta: { title: 'Campaña ' },
      //   component: () => import('../components/campaign/CampaignDetails.vue')
      // },

      /*       {
              path:'rgpd/:id',
              name: 'rgpd',
              meta: {title: 'rgpd '},
              component: () => import('../components/static/rgpd.vue')
            }, */


      // {
      //   path: 'mis-participaciones',
      //   name: 'participations',
      //   meta: { title: 'Participaciones ', requiresAuth: true },
      //   component: () => import('@/components/user/UserParticipations.vue')

      // },

      // {
      //   path: 'aviso-legal',
      //   name: 'legalNotice',
      //   meta: { title: 'Aviso Legal' },
      //   component: () => import('@/components/rgpd/'+process.env.VUE_APP_RGPD_FOLDER+'/LegalNotice.vue')

      // },
      // {
      //   path: 'condiciones-de-uso',
      //   name: 'termsOfService',
      //   meta: { title: 'Condiciones de uso ' },
      //   component: () => import('@/components/rgpd/'+process.env.VUE_APP_RGPD_FOLDER+'/TermsOfService.vue')

      // },  
      // {
      //   path: 'politica-de-privacidad',
      //   name: 'privacyPolicy',
      //   meta: { title: 'Política de privacidad ' },
      //   component: () => import('@/components/rgpd/'+process.env.VUE_APP_RGPD_FOLDER+'/PrivacyPolicy.vue')

      // },
      // {
      //   path: 'politica-de-cookies',
      //   name: 'cookiesPolicy',
      //   meta: { title: 'Política de Cookies ' },
      //   component: () => import('@/components/rgpd/'+process.env.VUE_APP_RGPD_FOLDER+'/CookiesPolicy.vue')

      // },
      // {
      //   path: 'politica-de-privacidad-app',
      //   name: 'privacyPolicyApp',
      //   meta: { title: 'Política de privacidad App' },
      //   component: () => import('@/components/rgpd/'+process.env.VUE_APP_RGPD_FOLDER+'/PrivacyPolicyApp.vue')

      // },
      // {
      //   path: 'bases-legales-lanzamiento',
      //   name: 'campaignOneBases',
      //   meta: { title: 'Bases legales Sorte Lanzamiento Molina de Segura Handy' },
      //   component: () => import('@/components/rgpd/'+process.env.VUE_APP_RGPD_FOLDER+'/CampaignOneBases.vue')
      // }, 
      {
        name: 'legalDetails',
        path: 'legal/:id',
        meta: { title: 'Legal ' },
        component: () => import('../components/rgpd/LegalDetails.vue'),

      },
      {
        name: 'error',
        path: '/:pathMatch(.*)*',
        meta: { title: '404 ' },
        component: () => import('@/components/errors/404.vue'),
      },
    ]
  },
];



const router = createRouter({

  scrollBehavior(to) {
    if (to.name !== 'landing') {
      // always scroll to top for routes other than 'landing'
      return { top: 0 };
    }
  },

  history: createWebHistory(process.env.BASE_URL),
  routes
});



router.beforeEach((to, from, next) => {
  document.title = to.meta.title+"- "+process.env.VUE_APP_NAME || process.env.VUE_APP_NAME;
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    if ((to.path === '/login' || to.path === '/registro') && store.getters.isAuthenticated) {
      next({ path: '/' });
    } else {
      next();
    }

  }


});

export default router;
