import axios from 'axios'

// axios.defaults.baseURL = 'https://admin.fortunahandy.com';
// axios.defaults.baseURL = 'https://admin.aguilashandy.com';
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_HOST;
axios.defaults.headers.common['Authorization'] = 'Bearer ABCDE';
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'https://fortunahandy.com';
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'https://aguilashandy.com';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_FRONTEND_HOST;




